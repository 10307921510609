import React, { useEffect, useState } from 'react';
import { pluck } from 'rxjs/operators';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { actions } from '@tg/core/store/modules/session';
import { RESOURCE_STATUS } from '@tg/core/config/constants';
import { FormBuilder, Heading, Avatar } from '@tg/core/components';
import AuthErrors from '../AuthErrors';

const Activate = () => {
  const { token } = useParams();
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState('default');
  const [isFetching, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [user, setUser] = useState(null);

  const onSubmit = (formData = { create_password: {} }) => {
    observableRequest({
      url: `auth/activate/${token}`,
      config: {
        method: 'PATCH',
        body: { ...formData.create_password },
      },
    })
      .pipe(pluck('data'))
      .subscribe(
        response => {
          if (
            response.status &&
            response.status === RESOURCE_STATUS.force_password_change
          ) {
            setUser(response.user);
            setView('password');
            setIsFetching(false);
            return;
          }
          dispatch(actions.createSession({ token: response.token }));
          history.replace({ pathname: '/' });
        },
        error => {
          dispatch(actions.destroySession());
          setApiError(error.response);
          setIsFetching(false);
        },
      );
  };

  useEffect(() => {
    onSubmit();
  }, []);

  switch (view) {
    case 'password':
      return (
        <>
          <Heading level='h1'>{t('activate.title')}</Heading>
          <div
            css={css`
              margin-bottom: 2rem;
            `}
          >
            <Avatar user={user} email />
          </div>
          <p>{t('activate.description')}</p>

          <AuthErrors error={apiError} />

          <FormBuilder
            onSubmit={onSubmit}
            isFetching={isFetching}
            errors={null}
            fieldsets={[
              {
                id: 'create_password',
                fieldset: 'create_password',
                title: null,
              },
              { id: 'accept_terms', fieldset: 'accept_terms', title: null },
            ]}
            submitButtonText={t('activate.submitButton')}
          />
        </>
      );

    default:
      return (
        <>
          <AuthErrors error={apiError} />
        </>
      );
  }
};

export default Activate;
