import React, { useState } from 'react';
import { pluck } from 'rxjs/operators';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import { actions } from '@tg/core/store/modules/session';
import { FormBuilder, Heading } from '@tg/core/components';
import AuthErrors from '../AuthErrors';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const { t } = useTranslation('auth');
  const [isFetching, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState(null);

  const onSubmit = formData => {
    setIsFetching(true);
    observableRequest({
      url: `auth/reset-password/${token}`,
      config: {
        method: 'PATCH',
        body: { ...formData.create_password },
      },
    })
      .pipe(pluck('data'))
      .subscribe(
        response => {
          dispatch(actions.createSession({ token: response.token }));
          history.replace({ pathname: '/' });
        },
        error => {
          dispatch(actions.destroySession());
          setApiError(error.response);
          setIsFetching(false);
        },
      );
  };

  return (
    <>
      <Heading level='h1'>{t('reset.title')}</Heading>
      <p>{t('reset.description')}</p>

      <AuthErrors error={apiError} />

      <FormBuilder
        onSubmit={onSubmit}
        isFetching={isFetching}
        errors={null}
        fieldsets={[
          { id: 'create_password', fieldset: 'create_password', title: null },
        ]}
        submitButtonText={t('reset.submitButton')}
      />
    </>
  );
};

export default ResetPassword;
