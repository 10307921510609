/* eslint-disable react/prop-types */
import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { requestWithDispatch } from '@tg/core/utils/requestHelpers';
import { transformCollectionResponse } from '@tg/core/utils/dataTransformers';
import { iff } from 'src/routes/Manage/components/editModalHelper';
import { Button, List } from '@tg/core/components';
import axios from 'axios';
import { useResource } from '@tg/core/hooks';
import { Modal } from 'semantic-ui-react';
import { capitalise } from '@tg/core/utils/stringHelpers';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import permissions from '@tg/core/constant';
import Form from './Form';

const initialState = {
  fetching: false,
  data: null,
  error: null,
  ui: {
    create: false,
    update: null,
  },
};

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    TIME_OFF_CREATE_NEW,
    TIME_OFF_PLANNED_YEARS_REPORT_EDIT,
  },
} = permissions;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_BEGIN':
    case 'CREATE_BEGIN':
    case 'UPDATE_BEGIN':
    case 'DELETE_BEGIN':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_END':
    case 'CREATE_END':
    case 'UPDATE_END':
    case 'DELETE_END':
      return {
        ...state,
        fetching: false,
      };

    case 'FETCH_SUCCESS':
    case 'CREATE_SUCCESS':
    case 'UPDATE_SUCCESS':
    case 'DELETE_SUCCESS':
      return {
        ...state,
        data: transformCollectionResponse(payload.data),
        ui: initialState.ui,
      };

    case 'FETCH_FAILURE':
    case 'CREATE_FAILURE':
    case 'UPDATE_FAILURE':
    case 'DELETE_FAILURE':
      return {
        ...state,
        error: payload.data,
      };

    case 'CREATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          create: true,
        },
      };

    case 'UPDATE':
      return {
        ...state,
        ui: {
          ...state.ui,
          update: payload,
        },
      };

    case 'CANCEL':
      return {
        ...state,
        ui: initialState,
      };

    default:
      return state;
  }
};

const Planners = ({ contract, setUI }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = useSelector(s => s.session.aut);
  const accesslist = useSelector(data => data?.access?.roles);
  const call = requestWithDispatch(dispatch, token);
  const { ROOT_API } = process.env;
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    call({ url: `contracts/${contract.id}/planners`, action: 'fetch' });
  }, []);

  useEffect(() => {
    setUI(state.ui);
  }, [state.ui]);

  const { data: event_types, isFetching } = useResource(
    { url: `contracts/${contract.id}/leave_types` },
    true,
  );

  const onCreateSubmit = formData => {
    call({
      url: `contracts/${contract.id}/planners`,
      action: 'create',
      method: 'post',
      data: { planner: formData },
    });
  };
  const onUpdateSubmit = formData => {
    const id = state.ui.update;
    call({
      url: `contracts/${contract.id}/planners/${id}`,
      action: 'update',
      method: 'patch',
      data: { planner: formData },
    });
  };
  const onDeleteSubmit = id => {
    call({
      url: `contracts/${contract.id}/planners/${id}`,
      action: 'delete',
      method: 'delete',
    });
  };
  const onReportSubmit = id => {
    axios({
      url: `${ROOT_API}/contracts/${contract.id}/planners/${id}/report`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'post',
    }).then(() => {
      setSuccessModal(true);
    });
  };
  const onCancel = () => dispatch({ type: 'CANCEL' });

  const selectEditableData = () => {
    return state?.data?.byId[state.ui.update];
  };

  const plannersData = state.data?.byId ? Object.keys(state.data?.byId) : [];
  const values = plannersData?.map(key => {
    return state.data.byId[key];
  });
  const leaveTypes = [];
  values.forEach(value =>
    value.planneryear_leaves.forEach(leave => {
      if (
        !leaveTypes.some(
          leaveType => leaveType.leaveTypeId === leave.leave_type_id,
        )
      ) {
        leaveTypes.push({
          leaveTypeId: leave.leave_type_id,
          leaveType: leave.leave_type,
        });
      }
    }),
  );
  const numOfGaps = leaveTypes.length + 2 - 1;
  const totalGapWidth = numOfGaps * 2;
  const num = (100 - totalGapWidth) / (leaveTypes.length + 3);
  return (
    <>
      {state.ui.create && (
        <Form
          onSubmit={onCreateSubmit}
          onCancel={onCancel}
          contract={contract}
          event_types={event_types}
          data={selectEditableData}
        />
      )}
      {state.ui.update && (
        <Form
          onSubmit={onUpdateSubmit}
          onCancel={onCancel}
          data={selectEditableData}
          contract={contract}
          event_types={event_types}
        />
      )}
      <div className='mb-4 overflow-x-auto'>
        {plannersData?.length ? (
          <List
            noBorder
            type='planner'
            headings={[
              {
                text: 'Year',
                width: leaveTypes.length < 5 ? `${num}%` : '80px ',
              },
              {
                text: 'Planner Period',
                width: leaveTypes.length < 5 ? `${num}%` : '80px ',
              },
              ...leaveTypes.map(leave => {
                return {
                  text: capitalise(leave.leaveType).replace(/-/g, ' '),
                  width: leaveTypes.length < 5 ? `${num}%` : '80px ',
                  align: 'center',
                };
              }),
              {
                text: '',
                width: leaveTypes.length < 5 ? `${num}%` : '130px ',
              },
            ]}
            items={plannersData.map(key => {
              const { id, year, planneryear_leaves, start_date, end_date } =
                state.data.byId[key];

              const editable = true;
              const deleteable = false;
              const reportable = true;

              return {
                id,
                columns: [
                  year,
                  `${start_date} To ${end_date}`,
                  ...leaveTypes.map(leave => {
                    const leaveTypeValue = planneryear_leaves.find(
                      plLeave => plLeave.leave_type_id === leave.leaveTypeId,
                    )?.leave_type_value;
                    return iff(
                      leaveTypeValue !== 0,
                      iff(
                        leaveTypeValue,
                        iff(
                          Array.isArray(leaveTypeValue),
                          leaveTypeValue?.length,
                          leaveTypeValue,
                        ),
                        '-',
                      ),
                      0,
                    );
                  }),
                  <span
                    className={`float-right ${
                      leaveTypes.length < 5
                        ? 'mr-4 space-x-4'
                        : 'mr-2 space-x-2'
                    }`}
                  >
                    <div className='flex items-center  space-x-2 '>
                      {getPermission(
                        accesslist,
                        TIME_OFF_PLANNED_YEARS_REPORT_EDIT,
                      ) && (
                        <>
                          {reportable && (
                            <Button
                              size='small'
                              color='tertiary'
                              onClick={() => onReportSubmit(id)}
                            >
                              Report
                            </Button>
                          )}
                          {editable && (
                            <Button
                              size='small'
                              color='tertiary'
                              onClick={() =>
                                dispatch({ type: 'UPDATE', payload: id })
                              }
                            >
                              Edit
                            </Button>
                          )}
                          {deleteable && (
                            <Button
                              size='small'
                              color='tertiary'
                              onClick={() => onDeleteSubmit(id)}
                            >
                              Delete
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </span>,
                ],
              };
            })}
          />
        ) : (
          'No Planners Found'
        )}
      </div>

      {getPermission(accesslist, TIME_OFF_CREATE_NEW) && (
        <Button
          disabled={isFetching}
          onClick={() => dispatch({ type: 'CREATE' })}
        >
          Create new
        </Button>
      )}
      <Modal
        open={successModal}
        header='Report created'
        content='Your report will be emailed to you shortly.'
        actions={['OK']}
        onClose={() => setSuccessModal(false)}
      />
    </>
  );
};

Planners.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default Planners;
