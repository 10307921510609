import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import { getActiveStorageUrl } from '@tg/core/utils/urlHelpers';
import {
  FileField,
  List,
  Button,
  PaginationControl,
} from '@tg/core/components';
import { Modal } from 'semantic-ui-react';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

const EmployeeDocuments = () => {
  const { employeeId } = useParams();
  const [page, setPage] = useState(1);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });
  const { data, postResource, deleteResource, isFetching, pagination } =
    useResource(
      { url: `employees/${employeeId}/documents?page=${page}&pagesize=10` },
      true,
    );

  const onDrop = useCallback(dropped => {
    const formData = new FormData();
    dropped.forEach(file => {
      formData.append('files[]', file);
    });
    postResource({
      formData,
      headers: {},
      onSuccess: setPage(1),
      onError: err => {
        if (err?.response?.data?.error?.includes('invalid file type')) {
          setIsValidFormat({
            message: err?.response?.data?.error,
            isValid: false,
          });
        }
      },
    });
  }, []);

  const onDeleteSuccess = () => {
    // Edge Case Scenario
    if (page > 1 && data.length % 10 === 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {/* <Heading level='h3'>Identity and Compliance Documents</Heading> */}

      <div className='mb-4'>
        <FileField onDrop={onDrop} multiple />
      </div>

      {data && !!data.length && (
        <List
          items={data.map(document => ({
            id: document.id,
            columns: [
              <a href={`${getActiveStorageUrl(document.url)}`}>
                {document.filename}
              </a>,

              <div className='flex justify-end'>
                <Button
                  size='small'
                  color='tertiary'
                  onClick={() =>
                    deleteResource({
                      id: document.id,
                      onSuccess: onDeleteSuccess,
                    })
                  }
                >
                  Delete
                </Button>
              </div>,
            ],
          }))}
        />
      )}
      {data && !data.length && !isFetching && 'No Documents Found'}
      {pagination && (
        <div>
          <PaginationControl {...pagination} onPageChange={setPage} />
        </div>
      )}
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={() => setIsValidFormat({ message: null, isValid: true })}
      />
    </>
  );
};

export default EmployeeDocuments;
