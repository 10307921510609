import React, { useState } from 'react';
import { finalize, pluck } from 'rxjs/operators';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading, FormBuilder } from '@tg/core/components';
import { observableRequest } from '@tg/core/utils/requestHelpers';
import AuthErrors from '../AuthErrors';

const ForgotPassword = () => {
  const { t } = useTranslation('auth');

  const [view, setView] = useState('default');
  const [email, setEmail] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [apiError, setApiError] = useState(null);

  const onSubmit = formData => {
    setIsFetching(true);
    setEmail(formData.forgot.email);
    observableRequest({
      url: 'auth/reset-password',
      config: {
        method: 'POST',
        body: { ...formData.forgot },
      },
    })
      .pipe(
        finalize(() => setIsFetching(false)),
        pluck('data'),
      )
      .subscribe(
        () => setView('success'),
        error => setApiError(error.response),
      );
  };

  switch (view) {
    case 'success':
      return (
        <>
          <Heading level='h1'>{t('forgot.successTitle')}</Heading>
          <p
            dangerouslySetInnerHTML={{
              __html: t('forgot.successDescription', {
                interpolation: { escapeValue: false },
                email,
              }),
            }}
          />
        </>
      );
    default:
      return (
        <>
          <Heading level='h1'>{t('forgot.title')}</Heading>
          <p>{t('forgot.description')}</p>

          <AuthErrors error={apiError} />

          <FormBuilder
            onSubmit={onSubmit}
            isFetching={isFetching}
            errors={null}
            fieldsets={[
              {
                id: 'forgot',
                fieldset: 'forgot',
                title: null,
                afterFields: <Link to='login'>{t('forgot.linkText')}</Link>,
              },
            ]}
            submitButtonText={t('forgot.submitButton')}
          />
        </>
      );
  }
};

export default ForgotPassword;
