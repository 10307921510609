import React, { FC, useState } from 'react';
import { Button, ButtonBar, Modal, Heading, Form } from '@tg/core/components';
import { useTranslation } from 'react-i18next';
import { Currency, Document } from 'src/types';
import currencySymbols from '@tg/core/utils/currencySymbols';
import { useResource } from '@tg/core/hooks';
import { ResourceOutput } from '@tg/core/hooks/useResource';
import { useSelector } from 'react-redux';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import FilePicker from './FilePicker';
import ErrorModal from '@tg/core/components/molecules/ErrorModal';

interface Props {
  defaultValues: FormData;
  payrollId: string;
  submission_id: string;
  userFullName: string;
  currency: Currency;
  emp_id: number;
  getList: ResourceOutput<FormData>['getResource'];
}

interface FormData {
  bonuses: string;
  expenses: string;
  commisions: string;
  receipts: File[] | Document[];
  notes: string;
}

const {
  EMPLOYER_DETAIL_VIEW: {
    PAYROLL: { DETAILED_MONTH_YEAR_VIEW_EDIT, DETAILED_MONTH_YEAR_VIEW_SUBMIT },
  },
} = permissions;

const ExpensesModal: FC<Props> = ({
  defaultValues,
  currency,
  payrollId,
  submission_id,
  emp_id,
  userFullName,
  getList,
}) => {
  const { t } = useTranslation(['payroll', 'forms']);
  const validExtensions = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'txt',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'csv',
    'ppt',
    'pptx',
  ];
  const [isOpen, setIsOpen] = useState(false);
  const accesslist = useSelector(state => state?.access?.roles);
  const [isValidFormat, setIsValidFormat] = useState({
    message: null,
    isValid: true,
  });
  const [errorFiless, setErrorFiless] = useState(0);

  const { patchResource, isFetching, errors } = useResource({
    url: `payroll_adjustments/submissions/${submission_id}/sheets`,
  });

  const { handleSubmit, getFieldProps } = Form.useForm<FormData>({
    validationSchema: null,
    defaultValues: {
      payroll: defaultValues,
    },
  });

  const [receipts, setReceipts] = useState([]);

  const onSubmit = (values: { payroll: FormData }) => {
    const {
      commisions,
      bonuses,
      expenses,
      notes,
      // receipts
    } = values.payroll;

    const formData = new FormData();
    if (receipts && receipts.length) {
      receipts.forEach(file => {
        // Newly uploaded files are a File, old ones are a Document type
        if (file instanceof File) {
          formData.append('receipts[]', file);
        }
      });
    }
    formData.append('commisions', commisions || '0.0');
    formData.append('bonuses', bonuses || '0.0');
    formData.append('expenses', expenses || '0.0');
    formData.append('notes', notes);

    patchResource({
      id: payrollId,
      formData,
      headers: {},
      onSuccess: () => {
        getList({
          onSuccess: () => setIsOpen(false),
        });
      },
      onError: err => {
        if (err?.response?.data?.error?.includes('invalid file type')) {
          setIsValidFormat({
            message: err?.response?.data?.error,
            isValid: false,
          });
        }
        if (err?.response?.error?.includes('is invalid')) {
          setIsValidFormat({
            message:
              'Commas are not allowed in the Expense, Bonus, or Commission fields. Please remove any commas and try again.',
            isValid: false,
          });
        }
      },
    });
  };

  const handleCheck = () => {
    return true;
  };

  const invalidFiles = receipts?.filter(file => {
    const extension = file?.path?.split('.').pop()?.toLowerCase();
    return !validExtensions?.includes(extension);
  })?.length;

  const handleErrorModalClose = () => {
    setErrorFiless(invalidFiles);
    setIsValidFormat({ message: null, isValid: true });
    setTimeout(() => {
      setErrorFiless(0);
    }, 500);
  };
  return (
    <>
      {getPermission(accesslist, DETAILED_MONTH_YEAR_VIEW_EDIT) && (
        <Button
          type='button'
          onClick={() => setIsOpen(true)}
          color='tertiary'
          size='small'
        >
          Edit
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        title={
          userFullName
            ? t('payroll:expenses.modal.title', {
                fullName: userFullName,
              })
            : ''
        }
        width='sm'
        onClose={() =>
          defaultValues.receipts.length
            ? getList({
                onSuccess: () => setIsOpen(false),
              })
            : setIsOpen(false)
        }
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          // isFetching={isFetching}
        >
          <Modal.Content>
            <div className='space-y-4'>
              <fieldset className='space-y-4'>
                {/* <Heading element='legend' level='h4' zeropad>
                  Bonuses
                </Heading> */}
                <div className='grid gap-4 grid-cols-2'>
                  <Form.TextFieldInput
                    {...getFieldProps({ id: 'payroll', name: 'bonuses' })}
                    defaultValue={defaultValues.bonuses}
                    postInput={currency.code}
                    type='number'
                    min='0'
                  />

                  <Form.TextFieldInput
                    {...getFieldProps({ id: 'payroll', name: 'commisions' })}
                    defaultValue={defaultValues.commisions}
                    postInput={currency.code}
                    type='number'
                    min='0'
                  />
                </div>
              </fieldset>
              <fieldset className='space-y-4'>
                <Heading element='legend' level='h4' zeropad>
                  Expenses
                </Heading>
                <div className='w-60'>
                  <Form.TextFieldInput
                    {...getFieldProps({ id: 'payroll', name: 'expenses' })}
                    defaultValue={defaultValues.expenses}
                    postInput={currency.code}
                    type='number'
                    min='0'
                  />
                </div>
                <Form.TextFieldInput
                  {...getFieldProps({ id: 'payroll', name: 'notes' })}
                  defaultValue={defaultValues.notes}
                  height='80px'
                />

                <FilePicker
                  getEmployeeId={emp_id}
                  defaultValue={defaultValues.receipts}
                  onChange={newFiles => setReceipts(newFiles)}
                  handleCheck={handleCheck}
                  fileError={errorFiless}
                />
              </fieldset>
            </div>
          </Modal.Content>
          {getPermission(accesslist, DETAILED_MONTH_YEAR_VIEW_SUBMIT) && (
            <ButtonBar>
              <Button type='submit' loading={isFetching}>
                Submit
              </Button>
            </ButtonBar>
          )}
        </Form>
      </Modal>
      <ErrorModal
        size='tiny'
        open={!isValidFormat?.isValid}
        header='Error'
        content={isValidFormat?.message}
        actions={['OK']}
        onClose={handleErrorModalClose}
      />
    </>
  );
};

export default ExpensesModal;
